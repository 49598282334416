import React, { useEffect, useState } from "react";
import "./App.css";
import * as XLSX from "xlsx";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Button, message, Modal } from "antd";

export default function App() {
  const [items, setItems] = useState([]);
  const [needChooseFile, setNeedChooseFile] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isTorchOn, setIsTorchOn] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);

  const [result, setResult] = useState(undefined);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      setIsLoading(true);
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const keys = data[1]; // First row as keys
        const jsonObjects = data.slice(1).map((row) => {
          return row.reduce((acc, value, index) => {
            acc[keys[index]] = value;
            return acc;
          }, {});
        });

        resolve(jsonObjects);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const videos = document.getElementsByTagName("video");

    if (isScannerOpen && videos && videos.length > 0) {
      const video = videos[0];
      const canvas = document.getElementById("overlayCanvas");
      const ctx = canvas.getContext("2d");

      // Function to resize and draw the rectangle
      function drawRectangle() {
        // Set canvas size to match the video
        canvas.width = video.clientWidth;
        canvas.height = video.clientHeight;

        // Clear the canvas for redrawing
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Calculate rectangle dimensions based on the video's dimensions
        const rectWidth = canvas.width * 0.9; // 90% of video width
        const rectHeight = canvas.height * 0.2; // 20% of video height

        // Calculate the position to center the rectangle
        const rectX = (canvas.width - rectWidth) / 2;
        const rectY = (canvas.height - rectHeight) / 2;

        // Draw the rectangle
        ctx.strokeStyle = "red"; // Customize color if desired
        ctx.lineWidth = 2; // Set line width
        ctx.setLineDash([5, 5]); // Set the pattern to dashed (5px dash, 5px gap)

        // Draw the rectangle with dotted lines
        ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);

        // Reset line dash pattern if needed
        ctx.setLineDash([]);
      }

      // Draw the rectangle when the video metadata loads
      video.addEventListener("loadedmetadata", drawRectangle);

      // Optional: Redraw if the video or window is resized
      window.addEventListener("resize", drawRectangle);
    }
  }, [isScannerOpen]);

  useEffect(() => {
    // Get the file URL from environment variables
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const fetchFile = async () => {
      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blob = await response.blob(); // Convert to Blob
        readExcel(blob); // Pass the Blob to readExcel
        setNeedChooseFile(false);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };
    if (fileUrl !== undefined) {
      console.log("fileUrl", fileUrl);
      fetchFile();
    } else {
      console.log("NO url");
      setNeedChooseFile(true);
    }
  }, []);

  return (
    <div>
      {isScannerOpen && items.length !== 0 && (
        <div>
          <canvas
            id="overlayCanvas"
            style={{ position: "absolute", top: 0, left: 0 }}
          ></canvas>
          <BarcodeScannerComponent
            width={Math.min(500, window.innerWidth)}
            height={Math.min(500, window.innerHeight)}
            delay={300}
            torch={isTorchOn}
            onUpdate={(err, res) => {
              if (res) {
                const text = res.getText();
                const products = items.filter((i) => i.单号 === text);
                if (products && products.length !== 0) {
                  setResult(products[0]);
                } else {
                  message.error("Scan successful, but no product found");
                }
              }
            }}
          />
        </div>
      )}
      <input
        type="file"
        disabled={isLoading || !needChooseFile}
        onChange={(e) => {
          const file = e.target.files[0];
          console.log("Start to read file", file);
          readExcel(file);
        }}
      />

      <button
        onClick={() => setIsScannerOpen(!isScannerOpen)}
        disabled={items.length === 0}
      >
        {isScannerOpen ? "Stop " : "Start "}Camera
      </button>

      <button
        onClick={() => setIsTorchOn(!isTorchOn)}
        disabled={!isScannerOpen}
      >
        {isTorchOn ? "Stop " : "Start "}Torch
      </button>

      <Modal
        title="Result"
        open={result !== undefined}
        footer={
          <Button variant="primary" onClick={() => setResult(undefined)}>
            OK
          </Button>
        }
      >
        <p>{result?.姓名首字母}</p>
        <p>{result?.快递}</p>
        <p>{result?.单号}</p>
      </Modal>
      <table className="table container">
        <thead>
          <tr>
            <th scope="col">Item</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {items.map((d) => (
            <tr key={d.编号}>
              <th>{d.编号}</th>
              <td>{d.姓名首字母}</td>
              {/* <td>{d.物品}</td> */}
              <td>{d.快递}</td>
              <td>{d.单号}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
